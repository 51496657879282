<template>
  <div class="mainBox">
    <div class="wrap">
      <div class="isLogo"><img :src="loadImg" alt="" ref="isImgRef" @click="isLogoClick"><span class="zi"></span>
        <div class="isTips" v-if="ishost == 'dll.wxqckj.cn'" @click="jumpToWeb">翘楚<br />下载网</div>
        <div class="isTips" v-else-if="ishost == 'dll.yzhzkj.cn'" @click="jumpToWeb">汇州<br />下载网</div>
      </div>
      <div class="rightBox">
        <div class="isNav" v-for="(item, index) in isList" :key="item.id" @click="toPageFn(item, index)">
          <!-- <div class="isNav"  v-for="(item,index) in isList" :key="index" :class="$route.name == item.name? 'active': ''" > -->
          <div>{{ item.title }}</div>
          <!-- <div class="isUnderLine" :style="{'opacity':$route.name == item.name || $route.name == item.rediret? 1 : 0}"></div> -->
        </div>
        <div class="downloadBtn" @click="downloadFn" style="cursor: pointer;">立即下载</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AllHead',
  data() {
    return {
      isList: [
        { title: '网站首页', id: 0 },
        // { title: 'DLL修复工具', id: 1 },
        { title: 'DirectX修复工具', id: 2 },
      ],
      ishost: '',

      loadImg: ''

    }
  },
  created() {
    let currentHost = window.location.host
    if (this.newShowTextList.includes(currentHost)) {
      this.isList = [
        { title: '网站首页', id: 0 },
        // { title: 'DLL修复工具', id: 1 },
        { title: 'dll修复/Directx修复', id: 2 },
      ]

    } else {
      this.isList = [
        { title: 'Directx类dII修复', id: 0 },
        { title: 'VC类dll修复', id: 1 },
        { title: 'Steam类dIl修复', id: 2 },
        { title: '.net修复', id: 3 },
      ]
    }
  },
  mounted() {
    this.ishost = window.location.host
    if (this.newShowTextList.includes(this.ishost)) {
      if ( this.ishost == 'dll.dlhykji.cn' ) {
        this.loadImg = require('../assets/img/logo1.png')
        this.$refs.isImgRef.classList.add('isImg1')
        this.$refs.isImgRef.classList.remove('isImg')
      } else if(this.ishost == 'dll.steampowere.top') {
        this.loadImg = require('../assets/img/ic_logo2.png')
        this.$refs.isImgRef.classList.add('isImg2')
        this.$refs.isImgRef.classList.remove('isImg')
      } else if (this.ishost == 'dll.fyswxx.cn'){
        this.loadImg = require('../assets/img/logo3.png')
        this.$refs.isImgRef.classList.add('isImg2')
        this.$refs.isImgRef.classList.remove('isImg')
      } else {
        this.loadImg = require('../assets/img/logo.png')
        this.$refs.isImgRef.classList.add('isImg')
        this.$refs.isImgRef.classList.remove('isImg1')
      }

    } else {
      this.loadImg = require('../assets/img/logo.png')
      this.$refs.isImgRef.classList.add('isImg')
      this.$refs.isImgRef.classList.remove('isImg1')
    }
  },
  methods: {
    jumpToWeb(){
      if ( this.ishost == 'dll.wxqckj.cn' ) {
        window.open('http://rjxz.wxqckj.cn/')
      } else if ( this.ishost == 'dll.yzhzkj.cn' ){
        window.open('https://store1.yzhzkj.cn/')
      }
    },
    toPageFn(item, index) {
      console.log(item, index);
      if (this.newShowTextList.includes(this.ishost)) {
        if (item.id == 0) {
          if ( this.ishost == 'dll.dlhykji.cn' ) {
            window.location.href = 'https://soft.dlhykji.cn/'
          } else if (this.ishost == 'dll.steampowere.top'){
            window.location.href = 'https://store1.steampowere.shop/'
          } else if (this.ishost == 'dll.fyswxx.cn'){
            window.location.href = 'https://store1.fyswxx.cn/'
          } else if (this.ishost == 'dll.yzhzkj.cn'){
            window.location.href = 'https://store1.yzhzkj.cn/'
          }
        } else if (item.id == 1) {
          window.location.href = window.location.origin + '/dll/' + window.location.search
        } else {
          console.log(item, index);
        }
      } else {
        return
      }
    },
    isLogoClick() {
      if (this.newShowTextList.includes(this.ishost)) {
        if ( this.ishost == 'dll.dlhykji.cn' ) {
          window.location.href = 'https://soft.dlhykji.cn/'
        } else if (this.ishost == 'dll.steampowere.top'){
          window.location.href = 'https://store1.steampowere.shop/'
        } else if (this.ishost == 'dll.fyswxx.cn'){
          window.location.href = 'https://store1.fyswxx.cn/'
        }
      }
    },
    toPage(v) {
      this.$router.push(this.isList[v].path)
    },
    goRoute(v) {
      let aaa = ['/', 'isAbout', 'lxUs']
      this.$router.push(aaa[v])
    }
  }
}
</script>
<style lang="scss" scoped>
.mainBox {
  width: 100%;
  height: 70px;

  // background-color: rgba(255,255,255,0.7);
  .wrap {
    width: 1180px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;

    .isLogo {
      width: 600px;
      height: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      position: relative;

      .isTips {
        position: absolute;
        left: -42px;
        top: 18px;
        font-size: 12px;
        text-align: center;
        color: #000;
        opacity: 0.8;
        cursor: pointer;
      }

      .isRouter {
        width: 200px;
        font-size: 14px;
        display: flex;
        justify-content: space-around;
        line-height: 28px;
        color: #5B5D61;
        font-weight: 600;

        .ishover {
          cursor: pointer;
        }

        // .ishover:hover {

        // }
      }

      img {
        width: 179px;
        height: 36px;
        object-fit: contain;
        cursor: pointer;
      }

      .isImg {
        width: 179px;
        height: 36px;
        object-fit: contain;
      }

      .isImg1 {
        width: 210px;
        height: 70px;
        object-fit: contain;
      }
      .isImg2 {
        width: 115px;
        height: 70px;
        object-fit: contain;
      }

      .zi {
        width: 160px;
        height: 28px;
        font-size: 14px;
        line-height: 28px;
        display: inline-block;
        margin-left: 10px;
        color: #5B5D61;
        font-weight: 600;
      }
    }

    .rightBox {
      width: 550px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .downloadBtn {
        width: 91px;
        height: 32px;
        background: #538BFF;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
        color: #fff;
      }

      .isNav {

        // width: 114px;
        height: 19px;
        font-size: 14px;
        font-weight: 600;
        color: #14142E;
        line-height: 16px;
        cursor: pointer;

        // cursor: pointer;
        // font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        .isUnderLine {
          height: 3px;
          margin-top: 3px;
          background-color: var(--base_color);
        }

      }

      .active {
        color: var(--base_color);
      }
    }
  }

}
</style>